import HavHabit from "./Components/HavHabit"
import SubscribeForm from "./SubscribeForm"

function Subscribe (){
	return (
		<>
			<HavHabit />
			<SubscribeForm />
		</>
	)
}

export default Subscribe